/*
This file is just for the body layout. To make it responsive, it works with wcmc_hero_threecol.js
Which duplicates the related-content sidebar contents into an area below the fold for smaller screens
*/

// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_clearfix";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_grid";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/bootstrap/mixins/_vendor-prefixes";

@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* =WCM Alumni Home
----------------------------------------------------------*/

.panel-alumni-home {
    position: relative;
    @include clearfix();
    padding-top: 25px;
}

// This overrides a style coming from Radix Layouts. Our gutter is a little bigger.
.container .container {
  margin-left: -20px;
  margin-right: -20px;
}

.hero-image img {
  width: 100%;
  height: auto;
  margin: 0 0 20px;
}

.hero-section {
    margin-bottom: 10px;
}

.middle-section {
    background: $wcm-bg-gray;
    margin-bottom: 20px;
    
    .col-md-4 {
        
        @include breakpoint($md) {
            &:after {
                content: '';
                height: 54%;
                position: absolute;
                top: 23%;
                right: 0;
                border-right: 1px solid $wcm-border-gray;
            }
        }
        
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 100px;
        /*@include breakpoint($md) {
            border-right: 1px solid $wcm-border-gray;
        }*/
        &:last-child {
            &:after {
                border-right: none;
            }
        }
        
        &:before {
            content: '';
            position: absolute;
            left: 20px;
            top: 40px;
            background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
            width: 64px;
            height: 64px;
        }
        &:nth-child(1):before {
              background-position: -80px -80px;
        }
        &:nth-child(2):before {
            background-position: -240px 0;
        }
        &:nth-child(3):before {
            background-position: -400px -80px;
        }
    }
}

.footer-section {
    background: $wcm-bg-gray;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    
    h1, h2, h3 {
        margin-bottom: 20px;
    }
    
    /*.pane-title {
        color: $wcm-dark-gray;
    }*/
    
    /*.container-fluid {
        background: rgba(221, 221, 221, 0.8);
    }*/
}


/* ----------- CSS for Alumni Enhancements ----------- */

.col-md-6.callouts {
    padding: 8px;
}

.main-callouts {
    @include breakpoint($lg) {
        width: 90%;
    }
    img.panopoly-image-full{
        mix-blend-mode: multiply;
        margin-bottom: 0;
    }
    
}

.med-callout {
    background-color: #B31B1B;
    transition: all .3s ease-out;

        &:hover{
            background: #7d0f0f;
            transition: all .3s ease-out;
            letter-spacing: 2.5px;
        }

        a{
            border: none;
        }

        @include breakpoint($xs) {
            margin-bottom: 20px;
        }

        @include breakpoint($sm) {
            margin-bottom:20px;
        }
        @include breakpoint($md) {
            margin-bottom:0;
        }





}

.grad-callout {
    background-color: #e7751d;
    transition: all .3s ease-out;

        &:hover{
            background: #b55914;
            transition: all .3s ease-out;
            letter-spacing: 2.5px;
        }

        a{
            border: none;
        }
}

.home-main-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    width: 100%;
    

        h2{
            color: #fff;
        }
    span.callout-welcome, span.callout-alumni {
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 5px;
    }
    span.callout-header{
        font-size: 9vw;
        width: 100%;
        text-transform: capitalize;
        font-family: "1898Sans-Regular",sans-serif;

            @include breakpoint($md){
                font-size: 4vw;
            }

            a{
                color: #fff;
                border: none;
            }
            &:after{
                content: '\e80d';
                color: #fff;
                padding-left: 20px;
                padding-bottom: 10px;
                font-size: 30%;
                vertical-align: middle;
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                text-decoration: none;
                line-height: 1.5;
            }
    }
    span.callout-welcome {
    padding: 7px 70px;
    border-bottom: 1px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    }

    span.callout-alumni {
    padding: 11px 80px;
    border-top: 1px solid rgb(255, 255, 255);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    }
}

h2.medical-text a {
    color: #fff;
}

.about-info {
    padding-top: 50px;
    font-size: large;
}

.front .view-news .view-teaser {
    border: none;
    width: 33%;
    float: left;
    padding-left: 20px;
    margin: 0 auto;

    @include breakpoint($md){

        h2.teaser-title{
            font-style: 18px;
            height: 60px;

        }
    }
        .post-date{
            font-size: 13px;
            padding: 10px 0;
        }

         @include breakpoint($xs){
            float: none;
            width: 100%;
            border-bottom: 1px solid #f1f1f1 !important;
            padding: 25px 20px;
                img{
                    width: 100%;
                }
            }

}

@media screen and (max-width: 1024px) and (min-width: 769px) {

    h2.teaser-title{
        height: 80px;
    }

}

.teaser-image {
    overflow: hidden;
    height: 210px;

    img{
        width: 100%;
        transition: all 0.3s ease 0s;

        &:hover{
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    img.panopoly-image-full{
        margin-bottom: 0;
    }
}

.teaser-text {
    margin-top: 10px;
}


.news-header {
    text-align: center;
    padding-bottom: 30px;

        h2{
            color: #666666;
        }
}


svg.cta-icon.cta-icon-social {
    color: #cf4520;
    fill: #e87722;
    width: 30px;
    height: 50px;
    position: relative;
    top: 5px;
    border: none;
    margin-left: 15px;

    &:after{
        display: none !important;
    }
}

.social-text{
    h3{
        float:left;
    }
    a{
        border: none;

        &:hover{
            border: none;
        }
    }

    .external-link:after{
        display: none !important;
    }
}

.main-callouts img.panopoly-image-full{
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     opacity: .3;
    }

    @supports (-ms-accelerator:true){
        opacity: .3;
    }
    @supports (-ms-ime-align:auto){
        opacity: .3;
    }
}
