/*
This file is just for the body layout. To make it responsive, it works with wcmc_hero_threecol.js
Which duplicates the related-content sidebar contents into an area below the fold for smaller screens
*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =WCM Alumni Home
----------------------------------------------------------*/
.panel-alumni-home {
  position: relative;
  padding-top: 25px; }
  .panel-alumni-home:before, .panel-alumni-home:after {
    content: " ";
    display: table; }
  .panel-alumni-home:after {
    clear: both; }

.container .container {
  margin-left: -20px;
  margin-right: -20px; }

.hero-image img {
  width: 100%;
  height: auto;
  margin: 0 0 20px; }

.hero-section {
  margin-bottom: 10px; }

.middle-section {
  background: #f7f7f7;
  margin-bottom: 20px; }
  .middle-section .col-md-4 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
    /*@include breakpoint($md) {
            border-right: 1px solid $wcm-border-gray;
        }*/ }
    @media screen and (min-width: 992px) {
      .middle-section .col-md-4:after {
        content: '';
        height: 54%;
        position: absolute;
        top: 23%;
        right: 0;
        border-right: 1px solid #dddddd; } }
    .middle-section .col-md-4:last-child:after {
      border-right: none; }
    .middle-section .col-md-4:before {
      content: '';
      position: absolute;
      left: 20px;
      top: 40px;
      background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg) no-repeat;
      width: 64px;
      height: 64px; }
    .middle-section .col-md-4:nth-child(1):before {
      background-position: -80px -80px; }
    .middle-section .col-md-4:nth-child(2):before {
      background-position: -240px 0; }
    .middle-section .col-md-4:nth-child(3):before {
      background-position: -400px -80px; }

.footer-section {
  background: #f7f7f7;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  /*.pane-title {
        color: $wcm-dark-gray;
    }*/
  /*.container-fluid {
        background: rgba(221, 221, 221, 0.8);
    }*/ }
  .footer-section h1, .footer-section h2, .footer-section h3 {
    margin-bottom: 20px; }

/* ----------- CSS for Alumni Enhancements ----------- */
.col-md-6.callouts {
  padding: 8px; }

@media screen and (min-width: 1200px) {
  .main-callouts {
    width: 90%; } }

.main-callouts img.panopoly-image-full {
  mix-blend-mode: multiply;
  margin-bottom: 0; }

.med-callout {
  background-color: #B31B1B;
  transition: all .3s ease-out; }
  .med-callout:hover {
    background: #7d0f0f;
    transition: all .3s ease-out;
    letter-spacing: 2.5px; }
  .med-callout a {
    border: none; }
  @media screen and (max-width: 767px) {
    .med-callout {
      margin-bottom: 20px; } }
  @media screen and (min-width: 768px) {
    .med-callout {
      margin-bottom: 20px; } }
  @media screen and (min-width: 992px) {
    .med-callout {
      margin-bottom: 0; } }

.grad-callout {
  background-color: #e7751d;
  transition: all .3s ease-out; }
  .grad-callout:hover {
    background: #b55914;
    transition: all .3s ease-out;
    letter-spacing: 2.5px; }
  .grad-callout a {
    border: none; }

.home-main-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  width: 100%; }
  .home-main-text h2 {
    color: #fff; }
  .home-main-text span.callout-welcome, .home-main-text span.callout-alumni {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 5px; }
  .home-main-text span.callout-header {
    font-size: 9vw;
    width: 100%;
    text-transform: capitalize;
    font-family: "1898Sans-Regular",sans-serif; }
    @media screen and (min-width: 992px) {
      .home-main-text span.callout-header {
        font-size: 4vw; } }
    .home-main-text span.callout-header a {
      color: #fff;
      border: none; }
    .home-main-text span.callout-header:after {
      content: '\e80d';
      color: #fff;
      padding-left: 20px;
      padding-bottom: 10px;
      font-size: 30%;
      vertical-align: middle;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-decoration: none;
      line-height: 1.5; }
  .home-main-text span.callout-welcome {
    padding: 7px 70px;
    border-bottom: 1px solid white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }
  .home-main-text span.callout-alumni {
    padding: 11px 80px;
    border-top: 1px solid white;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box; }

h2.medical-text a {
  color: #fff; }

.about-info {
  padding-top: 50px;
  font-size: large; }

.front .view-news .view-teaser {
  border: none;
  width: 33%;
  float: left;
  padding-left: 20px;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .front .view-news .view-teaser h2.teaser-title {
      font-style: 18px;
      height: 60px; } }
  .front .view-news .view-teaser .post-date {
    font-size: 13px;
    padding: 10px 0; }
  @media screen and (max-width: 767px) {
    .front .view-news .view-teaser {
      float: none;
      width: 100%;
      border-bottom: 1px solid #f1f1f1 !important;
      padding: 25px 20px; }
      .front .view-news .view-teaser img {
        width: 100%; } }

@media screen and (max-width: 1024px) and (min-width: 769px) {
  h2.teaser-title {
    height: 80px; } }

.teaser-image {
  overflow: hidden;
  height: 210px; }
  .teaser-image img {
    width: 100%;
    transition: all 0.3s ease 0s; }
    .teaser-image img:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1); }
  .teaser-image img.panopoly-image-full {
    margin-bottom: 0; }

.teaser-text {
  margin-top: 10px; }

.news-header {
  text-align: center;
  padding-bottom: 30px; }
  .news-header h2 {
    color: #666666; }

svg.cta-icon.cta-icon-social {
  color: #cf4520;
  fill: #e87722;
  width: 30px;
  height: 50px;
  position: relative;
  top: 5px;
  border: none;
  margin-left: 15px; }
  svg.cta-icon.cta-icon-social:after {
    display: none !important; }

.social-text h3 {
  float: left; }

.social-text a {
  border: none; }
  .social-text a:hover {
    border: none; }

.social-text .external-link:after {
  display: none !important; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-callouts img.panopoly-image-full {
    opacity: .3; } }

@supports (-ms-accelerator: true) {
  .main-callouts img.panopoly-image-full {
    opacity: .3; } }

@supports (-ms-ime-align: auto) {
  .main-callouts img.panopoly-image-full {
    opacity: .3; } }
